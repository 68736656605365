import React from "react"
import { header, logo, links } from "./index.style"

import resumeFile from "../../downloads/musta_rohman-resume-2020.pdf"

export default function Header() {
  return (
    <header css={header}>
      <div css={logo}>
        <a href="/">MUSTA ROHMAN</a>
      </div>
      <div css={links}>
        {/* TODO: UPDATE RESUME!! */}
        {/* <a href={resumeFile} download>
          RESUME
        </a> */}
      </div>
    </header>
  )
}
