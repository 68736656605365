import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { footer, text } from "./index.style"
import P from "../P"

export default function Footer() {
  const { site } = useStaticQuery(graphql`
    query MyQuery {
      site {
        siteMetadata {
          copyright
        }
      }
    }
  `)

  return (
    <div css={footer}>
      <P css={text}>{site.siteMetadata.copyright}</P>
    </div>
  )
}
