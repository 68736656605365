import React, { ReactNode } from "react"
import { globals } from "./index.style"
import { Global } from "@emotion/core"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

interface Props {
  children: ReactNode
}

export default function Layout(props: Props) {
  const { children } = props
  return (
    <>
      <Global styles={globals} />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}
