import { CSSObject } from "@emotion/core"
import { dark900 } from "../../containers/Layout/index.style"

export const footer: CSSObject = {
  height: "45px",
  border: "solid 1px #979797",
  backgroundColor: dark900,
  color: "white",
  marginBottom: "15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

export const text: CSSObject = {
  color: "white",
}
