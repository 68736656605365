import { CSSObject } from "@emotion/core"

export const contentWidth = "710px"

export enum BREAKPOINT {
  LG = 1200,
  MD = 992,
  SM = 768,
  XS = 576,
}

export const font = "Lato"

export const dark1000 = "#000000"
export const dark900 = "#253238"
export const dark800 = "#607d8b"
export const pink700 = "#F7F0F0"
export const red900 = "#e74e40"

export const defaultBoxShadow = "0 2px 4px 0 rgba(0, 0, 0, 0.5)"

export const containerPadding = "21px 16px"

export const getMediaQuery = (breakpoint: BREAKPOINT) => {
  return `@media (min-width: ${breakpoint}px)`
}

export const globals: CSSObject = {
  html: {
    fontFamily: "Lato",
    msTextSizeAdjust: "100%",
    WebkitTextSizeAdjust: "100%",
    font: "112.5%/1.45em Lato, serif",
    boxSizing: "border-box",
    overflowY: "scroll",
  },
  body: {
    margin: 0,
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    color: "hsla(0, 0%, 0%, 0.8)",
    fontFamily: "Lato",
    fontWeight: "normal",
    wordWrap: "break-word",
    fontKerning: "normal",
    MozFontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
    fontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
    WebkitFontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
  },
}
