import styled from "@emotion/styled"
import { font, dark1000 } from "../../containers/Layout/index.style"

const P = styled.p({
  fontFamily: font,
  fontWeight: 300,
  fontSize: "16px",
  color: dark1000,
})

export default P
